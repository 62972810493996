<template>
  <div class="box">
    <template v-if="live_couse_list.length">
      <!-- @click="gotovideo(item)" -->
      <div v-for="(item, i) in live_couse_list" :key="i" class="live_left">
        <div class="top_img img_box">
          <img :src="item.cover" class="img" />
          <!-- <div class="mask">
          <img src="@/assets/play.png" class="icon">
        </div> -->
        </div>
        <div class="bootm">
          <div class="name">{{ item.name }}</div>
          <!-- <div class="time" style="margin-bottom: 10px">
            直播时间：{{ item.live_start_at }} - {{ item.live_end_at }}
          </div> -->
          <div class="flex" style="width: 100%; justify-content: space-between">
            <div class="bot_left flex">
              <img alt="" src="@/assets/live_icon.png" />
              <div class="flex">
                <div class="num">{{ item.has_been_nums }}</div>
                <span>人已报名</span>
              </div>
            </div>
            <div class="btn">直播待开放</div>
            <!-- <div v-if="item.is_has_been_join == 0" class="btn" @click.stop="go_apply(item)">现在报名</div>
            <div class="btn" v-if="item.is_has_been_join != 0 && item.status == 0">已报名</div>
            <div class="btn" v-if="item.is_has_been_join != 0 && item.status == 1" @click="gotovideo(item)">进入直播</div> -->
          </div>
        </div>
      </div>
    </template>
    <div class="flex" style="justify-content: center; width: 100%;" v-else>
      <el-empty description="暂无直播"></el-empty>
    </div>
    <!-- <div class="live_right">
      <template v-for="(item,i) in live_couse_list">
        <div class="item" :key="i" v-if="i > 0 && i < 7">

          <div class="img_box">
            <img :src="item.cover" class="top_img" alt="">
          </div>
          <div class="bot_box">
            <div class="name line-1">{{ item.name }}</div>
            <div class="time">{{ item.live_start_at  }} - {{ item.live_end_at }}</div>
            <div class="bootm_box flex">
              <div class="left_box flex">
                <img src="@/assets/live_icon.png" alt="">
                {{ item.has_been_nums }}
                <span>人报名</span>
              </div>
              <div class="btn" v-if="item.is_has_been_join == 0" @click="apply(item)">现在报名</div>
              <div class="btn" v-else>已报名</div>
            </div>
          </div>
        </div>
      </template>

    </div> -->



    <el-dialog title="提示" :visible.sync="dialogVisible" width="20%" :center="true" custom-class="border_radius"
      top="20vh">
      <span style="color: #333">
        该功能仅限会员使用，点击查询会员申请方法
      </span>
      <span slot="footer">
        <el-button type="primary" style="background: #103994 !important;" @click="go_page">
          查询会员申请方法
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  live_course,
  live_broadcast_course_store,
  live_broadcast_course_info,
  sub_activity_apply
} from "@/api/course";
import { my_user } from '@/api/login';
export default {
  data() {
    return {
      dialogVisible: false,
      live_couse_list: [],
      url: "/direct_seeding",
      urls: "/directorder",
      datas: {},
    };
  },
  created() {
    this.get_live_course();
  },
  // 组件方法
  methods: {
    // 不是会员
    no_vip() {
      this.dialogVisible = true;
    },
    go_page() {
      this.dialogVisible = false;
      let routeUrl = this.$router.resolve({
        path: '/concat',
        query: {
          id: 485,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    gotovideo(item) {
      if (item.is_has_been_join == 0) {
        this.go_apply(item);
        return;
      }
      live_broadcast_course_info(item.id).then((res) => {
        if (res.code == 200) {
          window.open(res.data.live_url)
        }
        // if (res.code == 200) {
        //   let routeUrl = this.$router.resolve({
        //     path: this.url,
        //     query: { key: item.id },
        //   });
        //   window.open(routeUrl.href, "_blank");
        // }
      });
    },
    get_live_course() {
      live_course().then((res) => {
        this.live_couse_list = res.data;
      });
    },
    /**
      * @description: 直播报名  第一个参数type 0活动 1直播 第二个参数活动的id
    */
    async live_apply(item) {
      let res = await sub_activity_apply(1, item.id);
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: 'success'
        });
        this.get_live_course();
      }
    },
    async go_apply(item) {
      if (localStorage.getItem('token')) {
        let info = {};
        let res = await my_user();
        if (res.code == 200) {
          info = res.data;
          localStorage.setItem('info', JSON.stringify(res.data));
          this.$store.commit('get_info', res.data);
        }
        // 是会员
        if (info.status_arr.code == 1) {
          this.yes_vip(item);
        } else {
          this.no_vip();
        }
      } else {
        this.no_login();
      }
    },
    no_login() {
      this.$alert('登录后才可以报名', '请先登录', {
        confirmButtonText: '立即登录',
        type: 'error',
        confirmButtonClass: 'confirmButtons',
        callback: action => {
          if (action == 'confirm') {
            this.$router.push('/login');
          }
        },
      });
    },
    yes_vip(item) {
      this.$confirm(`确认报名${item.name}这场直播吗？`, "确认报名", {
        distinguishCancelAndClose: true,
        confirmButtonText: "报名",
        cancelButtonText: "取消",
      }).then(() => {
        // 直播
        this.live_apply(item);
        // this.datas = {};
        // live_broadcast_course_store({
        //   live_broadcast_course_id: item.id,
        // }).then((res) => {
        //   if (res.code == 200) {
        //     this.datas = res.data;
        //     this.datas.name = item.name;
        //     this.datas.cover = item.cover;
        //     this.$router.push({ path: this.urls, query: { data: this.datas } });
        //     // item.is_has_been_join = 1
        //     // this.get_live_course()
        //     // that.$message({
        //     //   type: 'success',
        //     //   message: '报名成功'
        //     // })
        //   }
        // });
      }).catch(() => { });
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  // display: flex;

  padding-bottom: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.live_left {
  width: calc(25% - 30px);
  margin: 0 30px 30px 0;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;

  // .top_img{}
  // .img {
  //   height: 180px;
  //   width: 100%;
  //   border-radius: 10px;
  //   object-fit: cover;
  // }
  .bootm {
    padding: 10px;
    color: #909090;
    font-size: 16px;
    background: #ffffff;
    border-radius: 0 0 10px 10px;

    .name {
      margin-bottom: 10px;
      color: #333;
      font-weight: bold;
    }

    .num {
      color: #333;
      margin-left: 10px;
    }

    img {
      width: 24px;
      height: 24px;
    }

    .btn {
      width: 81px;
      height: 32px;
      background: #103994;
      border-radius: 16px;
      text-align: center;
      line-height: 32px;
      color: #fff;
      font-size: 14px;
    }
  }

  .top_img {
    width: 100%;
    height: 240px;
    border-radius: 10px;
    position: relative;

    .img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    .mask {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .icon {
        width: 46px;
        height: 46px;
      }
    }
  }
}

::v-deep .el-button--primary {
  background-color: #103994;
  border-color: #103994;
}

.live_right {
  flex: 1;
  flex-wrap: wrap;
  // justify-content: space-between;
  padding-left: 20px;
  display: flex;

  .item:hover {
    box-shadow: 0px 5px 10px 3px #dbdbdb;
  }

  .item {
    width: 240px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
    max-height: 250px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .top_img {
      width: 100%;
      height: 140px;
      border-radius: 10px;
    }

    .bot_box {
      padding: 10px;
      padding-top: 0;

      .name {
        color: #333;
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: bold;
      }

      .time {
        font-size: 12px;
      }
    }

    .bootm_box {
      justify-content: space-between;

      .left_box {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;

        span {
          color: #909090;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }

      .btn {
        width: 81px;
        height: 26px;
        background: #103994;
        border-radius: 16px;
        color: #fff;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
}
</style>
