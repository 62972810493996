import http from '../utils/http'
// 系列部分接口
// 全部系列
export function series_list(params) {
	return http.post(`/api/series/list`, params)
}
// 系列详情
export function series_detail(params) {
	return http.post(`/api/series/detail`, params)
}
// 套餐下单 
export function store_order(params) {
	return http.post(`/api/order/store`, params)
}
// 单课程下单 
export function buy_course(params) {
	return http.post(`/api/order/buy_course`, params)
}

// 直播 微信支付宝支付 
export function buy_zhibo(params, data) {
	return http.post(`/api/live_broadcast_course_order_wexin_payment/${params}`, data)
}
//直播 在线转账
export function zhibo_zz(params,data) {
	return http.post(`/api/live_broadcast_course_order_bank_payment/${params}`,data)
}
//直播 点数支付
export function zhibo_dian(params) {
	return http.post(`/api/live_broadcast_course_order_balance_pay_payment/${params}`,)
}
// 支付 
export function order_payment(params) {
	return http.post(`/api/order_payment`, params)
}
// 提交选课信息 
export function select_course(params) {
	return http.post(`/api/order/select_course`, params)
}
// 系列及级别筛选选项
export function select_options(params) {
	return http.post(`/api/order/select_options`, params)
}
// 我的-已选课程
export function my_course(params) {
	return http.post(`/api/order/my_course`, params)
}
// 获取课程上一次学习章节
export function last_learn_section(params) {
	return http.post(`/api/course/last_learn_section`, params)
}
// 章节详情
export function section_detail(params) {
	return http.post(`/api/course/section_detail`, params)
}
// 课程产品大纲 /480
export function goods_chapter(params) {
	return http.post(`/api/get_goods_chapter/${params}`,)
}