<template>
  <div class="home">
    <div class="swiperBox" v-loading="loading" element-loading-background="rgba(255, 255, 255, 0.3)">
      <el-carousel height="650px" :interval="5000">
        <el-carousel-item v-for="(item, index) in banner_list" :key="index">
          <div class="swiperItemBox" @click="jump_link(item)">
            <img :src="item.cover" class="swiperItem" alt="" />
            <!-- <ul class="swiperMenu" v-if="index === 0">
              <li class="menuItem" @click="go_page('/resource_download')">
                点击进入
              </li>
            </ul> -->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="list w">
      <!-- 推荐课程 -->
      <div class="course">
        <!-- 课程列表 -->
        <course-list></course-list>
      </div>
      <!-- 活动事件 -->
      <div class="activity">
        <div class="list_title p0 flex">
          <span>活动事件</span>
          <div class="move" @click="go_page('/news')">查看更多 ></div>
        </div>
        <indexActiity></indexActiity>
      </div>
      <!--  直播报名 -->
      <div class="live">
        <div class="list_title flex">
          <span>直播课程</span>
          <!-- <div class="move">查看更多 ></div> -->
        </div>
        <indexLiveApply></indexLiveApply>
      </div>
      <!-- 直播 -->
      <!-- <div class="alive">
              <div class="list_title flex">
                <span>直播ALIVE</span>
                <div class="move">查看更多 ></div>
              </div>
              <indexAlive></indexAlive>
            </div> -->
    </div>
  </div>
</template>

<script>
import courseList from "@/components/course_list.vue";
import indexActiity from "@/components/index_activity.vue";
import indexLiveApply from "@/components/index_live_apply.vue";
import indexAlive from "@/components/index_alive.vue";
import { test, index_banner, my_user } from "@/api/login";

export default {
  name: "HomeView",
  components: {
    courseList,
    indexActiity,
    indexLiveApply,
    // indexAlive,
  },
  props: {},
  data() {
    return {
      url: process.env,
      banner_list: [],
      loading: true,
    };
  },
  created() {
    this.$emit("header", true);
    let token = localStorage.getItem("token");
    if (!token) this.$router.push('/login');
  },
  mounted() {
    this.getbanner();
    // if (localStorage.getItem('token')) {
    this.getinfo();
    // }
  },
  // 组件方法
  methods: {
    /**
     * @description: 跳转
     * @param {*} item 轮播图某一项
     */
    jump_link(item) {
      if (!item.jump_link) return;
      window.open(item.jump_link)
    },
    getbanner() {
      index_banner().then((res) => {
        this.banner_list = res.data;
        this.loading = false;
      });
    },
    go_page(url) {
      this.$router.push(url);
    },
    go_books() {
      this.$router.push("/recommend_course");
    },
    getinfo() {
      my_user().then((res) => {
        localStorage.setItem("info", JSON.stringify(res.data));
        let img = res.data.personal_photos;
        localStorage.setItem("avatar", img[0]);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  background: #f3f4f7;
}

.swiperBox {
  height: 650px;

  .swiperItemBox {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .swiperItem {
    width: 100%;
    height: 100%;
    background-color: #5179e7;
    object-fit: cover;
  }

  .swiperMenu {
    position: absolute;
    right: calc(50% - 190px);
    bottom: calc(50% - 45px);
    background-color: #103994;
    padding: 10px 16px 10px 24px;
    border-radius: 18px;
    font-size: 22px;
  }

  .menuItem {
    cursor: pointer;
    color: white;
    letter-spacing: 8px;
  }
}

.list {
  .list_title {
    padding: 30px 0;

    span {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #383838;
    }

    .move {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #383838;
      margin-left: 30px;
      cursor: pointer;
    }
  }

  .p0 {
    padding-top: 0;
  }
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

::v-deep .el-carousel__button {
  width: 14px;
  height: 14px;
  background: #ffffff;
  opacity: 0.8;
  border-radius: 14px;
  margin-right: 10px;
}

::v-deep .el-calendar-table .el-calendar-day {
  height: 63px;
}
</style>
