import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
// const hou = '国家留学基金管理委员会来华留学教育专才发展计划-'
const hou = 'CSC人才在线-';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: hou + '首页' },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: { title: hou + '登录' },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue'),
    meta: { title: hou + '注册' },
  },
  {
    path: '/submit',
    name: 'submit',
    component: () => import('../views/login/submit.vue'),
    meta: { title: hou + '注册' },
  },
  {
    path: '/audit',
    name: 'audit',
    component: () => import('../views/login/audit.vue'),
    meta: { title: hou + '信息审核' },
  },
  {
    path: '/forget_password',
    name: 'forget_password',
    component: () => import('../views/login/forget_password.vue'),
    meta: { title: hou + '忘记密码' },
  },
  {
    path: '/all_course',
    name: 'all_course',
    component: () => import('../views/course/course_all.vue'),
    meta: { title: hou + '全部课程系列' },
  },
  {
    path: '/course_series',
    name: 'course_series',
    component: () => import('../views/course/course_series.vue'),
    meta: { title: hou + '课程系列' },
  },
  {
    path: '/course_seriess',
    name: 'course_seriess',
    component: () => import('../views/course/course_seriess.vue'),
    meta: { title: hou + '选择课程' },
  },
  {
    path: '/course_details',
    name: 'course_details',
    component: () => import('../views/course/course_details.vue'),
    meta: { title: hou + '课程详情' },
  },
  {
    path: '/confirm_order',
    name: 'confirm_order',
    component: () => import('../views/order/confirm_order.vue'),
    meta: { title: hou + '确定订单' },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/order/payment.vue'),
    meta: { title: hou + '支付订单' },
  },
  {
    path: '/recommend_course',
    name: 'recommend_course',
    component: () => import('../views/course/recommend_course.vue'),
    meta: { title: hou + '推荐课程' },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news/news.vue'),
    meta: { title: hou + '新闻活动' },
  },
  {
    path: '/news_details',
    name: 'news_details',
    component: () => import('../views/news/news_details.vue'),
    meta: { title: hou + '新闻详情' },
  },
  {
    path: '/activity_details',
    name: 'activity_details',
    component: () => import('../views/news/activity_details.vue'),
    meta: { title: hou + '详情' },
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import('../views/certificate/certificate.vue'),
    meta: { title: hou + '证书查询' },
  },
  {
    path: '/resource_download',
    name: 'resource_download',
    component: () => import('../views/info/components/resource_download.vue'),
    meta: { title: hou + '资源下载' },
  },
  {
    path: '/direct_seeding',
    name: 'direct_seeding',
    component: () => import('../views/info/components/direct_seeding.vue'),
    meta: { title: hou + '直播视频' },
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/info/my_info.vue'),
    meta: { title: hou + '我的' },
  },
  {
    path: '/play_video',
    name: 'play_video',
    component: () => import('../views/play_video/play_video.vue'),
    meta: { title: hou + '视频' },
  },
  {
    path: '/place_order',
    name: 'place_order',
    component: () => import('../views/order/place_order.vue'),
    meta: { title: hou + '确定订单' },
  },
  {
    path: '/directorder',
    name: 'directorder',
    component: () => import('../views/order/directorder.vue'),
    meta: { title: hou + '确定订单' },
  },
  {
    path: '/concat',
    name: 'concat',
    component: () => import('../views/concat.vue'),
    meta: { title: hou + '关于我们' },
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/info/feedback.vue'),
    meta: { title: hou + '意见反馈' },
  },
  {
    path: '/myFeedback',
    name: 'myFeedback',
    component: () => import('../views/info/myFeedback.vue'),
    meta: { title: hou + '我的反馈' },
  },
  {
    path: '/feedbackDetails',
    name: 'feedbackDetails',
    component: () => import('../views/info/feedbackDetails.vue'),
    meta: { title: hou + '反馈详情' },
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    };
  },
});
// router.beforeEach((to, from, next) => {
//     if (to.path === '/login') {
//         next();
//         return;
//     }
//     let token = localStorage.getItem('token');
//     if (!token) {
//         next('/login')
//         return;
//     }
//     next();
// })

export default router;
