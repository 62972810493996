<template>
  <div class="">
    <div class="w box flex">
      <div class="left flex">
        <div class="calendar">
          <Calendar :sundayStart="true" @choseDay="handleclickDay"></Calendar>
        </div>
        <div class="calendar_right">
          <div class="top">
            <div class="moth">{{ date }}</div>
            <div class="mun">{{ day }}</div>
            <div class="text">{{getDayCn(date,day).ncWeek}}</div>
            <!-- <div class="text">辛亥月 戊實日</div> -->
          </div>
          <div class="bot">
            <div v-if="today_activity">
              <div class="title">今日活动</div>
              <div>
                <div class="name">{{ today_activity.name }}</div>
                <div class="btn" @click="toactivity_detail(today_activity.id)">
                  点击查看
                </div>
              </div>
            </div>
            <div v-else>
              <div class="title">今日暂无活动</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tabs flex">
          <div class="tab" @click="changeList(1)" :class="{ 'avtive': current == 1 }">热门活动</div>
          <div class="tab" @click="changeList(0)" :class="{ 'avtive': current == 0 }">热门新闻</div>

        </div>
        <div v-if="list.length">
          <template v-for="(item, i) in list">
            <div class="item" v-if="i < 3 && current == 0" :key="i" @click="go_news(item.id)">
              <div class="img_box">
                <img :src="item.cover" class="img" />
              </div>
              <div class="item_right">
                <div class="title line-2">{{ item.title }}</div>
                <div class="flex bottom">
                  <div class="time">
                    发布时间: {{ item.created_at.slice(0, 11) }}
                  </div>
                  <div class="num">浏览量: {{ item.clicks }}</div>
                </div>
              </div>
            </div>
            <div class="item" v-if="i < 3 && current == 1" :key="item.id"
              @click="go_huo_dong(item.id)">
              <div class="img_box">
                <img :src="item.cover" class="img" />
              </div>
              <div class="item_right">
                <div class="title line-2">{{ item.name }}</div>
                <div class="flex bottom" style="justify-content: space-between;">
                  <div class="time">活动时间: {{ item.activity_at }}</div>
                  <div class="btn">点击查看</div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { index_news, get_day_activity, get_activity } from "@/api/login";
import Calendar from "./calender";
import solarLunar from "solarlunar";
export default {
  name: "index_activity",
  components: {
    Calendar,
  },
  data() {
    return {
      value: "",

      date: "",
      day: "",
      list: [],
      list2: [],
      today_activity: null,
      arr: [],
      current: 1,
    };
  },
  mounted() {
    this.getdate();
    // this.getlist();
    this.getactivity();
    this.getActivityList();
  },
  // 组件方法
  methods: {
    /**
     * @description: 获取农历
     * @param {*} date 年月日
     */
    getDayCn(date) {
      let newDate = date.split("-");
      return solarLunar.solar2lunar(newDate[0], newDate[1], newDate[2]);
    },
    go_huo_dong(id) {
      let routeUrl = this.$router.resolve({
        path: "/activity_details",
        query: { id: id },
      });
      window.open(routeUrl.href, "_blank");
    },
    changeList(index) {
      this.list = [];
      if (index == 0) {
        this.getlist();
      } else {
        this.getActivityList();
      }
      this.current = index;
    },
    getActivityList() {
      get_activity().then((res) => {
        this.list = res.data;
      });
    },
    handleclickDay(data) {
      this.date = data;
      let alldate = this.date.split("-");
      this.day = alldate[2];
      this.getactivity();
    },
    go_news(id) {
      let routeUrl = this.$router.resolve({
        path: "/news_details",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    getdate() {
      let d = new Date();
      let y = d.getFullYear();
      let m = d.getMonth() + 1;
      let day = d.getDate();
      if (day < 10) {
        this.day = "0" + day;
      } else {
        this.day = day;
      }

      this.date = y + "-" + m + "-" + day;
    },
    chooseDay(data) {
      this.date = data.day;
      this.day = data.day.slice(8, 11);
      this.getactivity();
    },
    getlist() {
      index_news().then((res) => {
        this.list = res.data.data;
      });
    },
    getactivity() {
      get_day_activity({
        date: this.date,
      }).then((res) => {
        if (res.data != "") {
          this.today_activity = res.data[0];
        } else {
          this.today_activity = null;
        }
      });
    },
    toactivity_detail(id) {
      let routeUrl = this.$router.resolve({
        path: "/activity_details",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .wh_container {
  width: 510px;
  height: 100%;

  .wh_content_all {
    width: 500px;
    height: 100%;
  }

  .wh_top_changge li {
    color: #000;
  }

  .wh_jiantou1 {
    border-top: 2px solid #000;
    border-left: 2px solid #000;
  }

  .wh_jiantou2 {
    border-top: 2px solid #000;
    border-right: 2px solid #000;
  }

  .wh_content_all {
    background: #fff !important;
    border: 1px solid rgba(238, 238, 238, 1);
  }

  .wh_content_item {
    color: #000;
  }

  .wh_item_date:hover {
    background: blue;
    color: #fff;
    border-radius: 100%;
  }

  .wh_content_item .wh_isToday {
    background: #186cff;
    color: #fff;
  }

  .wh_content_item>.wh_isMark {
    margin: auto;
    border-radius: 100px;
    background-color: rgba(0, 0, 255, 0.3);
    color: #fff;
    z-index: 2;
  }

  .wh_content_item .wh_chose_day {
    background: blue !important;
    color: #fff;
  }
}

.is-selected {
  color: #1989fa;
}

.dian {
  background-color: #1989fa;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 10px;
  margin-left: 20px;
}

::v-deep .el-calendar-table .el-calendar-day {
  padding: 0px !important;
}

::v-deep .wh_container {
  margin: 0 !important;
}

.to_check {
  width: 100%;
  height: 100%;
  padding: 8px;
}

.box {
  height: 534px;

  .left {
    width: 650px;
    height: 100%;
    display: flex;

    .calendar {
      width: 500px;
      height: 100%;
    }

    .calendar_right {
      width: 140px;
      height: 100%;
      background-color: #123982;
      border-radius: 0 10px 10px 0;
      color: #fff;

      .top {
        background: #0d295d;
        width: 100%;
        padding: 20px;
        font-size: 12px;
        text-align: center;

        .mun {
          font-size: 30px;
          padding: 20px;
          background-color: #1748a5;
          border-radius: 20px;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .text {
          margin-top: 5px;
        }
      }

      .bot {
        padding-top: 20px;
        text-align: center;

        .name {
          margin-top: 20px;
        }

        .btn {
          border-radius: 20px;
          background-color: #fff;
          width: 100px;
          height: 30px;
          line-height: 30px;
          margin: 20px auto;
          color: #103994;
        }
      }
    }
  }

  .right {
    width: calc(100% - 650px);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 20px;
    // justify-content: space-between;

    .tabs {
      margin-bottom: 40px;

      .tab {
        width: 92px;
        height: 42px;
        background: #ffffff;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
        font-size: 16px;
        color: #909090;
        margin-right: 20px;
        text-align: center;
        line-height: 42px;
        cursor: pointer;
      }

      .avtive {
        background: #103994;
        color: #fff;
        border: 1px solid #103994;
        font-weight: bold;
      }
    }

    .item:hover {
      border: 1px solid #103994;
    }

    .item {
      padding: 10px;
      background: #ffffff;
      border: 1px solid transparent;
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      width: 100%;
      cursor: pointer;
      margin-bottom: 10px;



      &:last-child {
        margin: 0;
      }

      img {
        vertical-align: middle;
      }

      .img {
        width: 210px;
        height: 125px;
        border-radius: 5px;
      }

      .item_right {
        width: calc(100% - 210px);
        padding: 10px;
        font-family: Microsoft YaHei;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: 16px;
          font-weight: bold;
          color: #383838;
        }

        .describe {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #909090;
        }

        .bottom {
          font-size: 14px;
          color: #909090;
          .time {
            margin-right: 30px;
          }

          .btn {
            background: #103994;
            color: #fff;
            width: 90px;
            height: 33px;
            border-radius: 10px;
          

          }
        }
      }
    }
  }
}
</style>
