<template>
  <div class="footer ">
    <div class="footer_box">
      <div class="box w">
        <div class="column">
          <div class="flex">
            <img :src="sys_config_data.web_logo" v-if="sys_config_data.web_logo" class="logo">
            <div class="text">
              <div class="title flex" style="align-items: flex-end;">
                <img src="@/assets/logotext.png" style="width: 200px;height: 45px;object-fit: contain;">
                <span style="margin-left: 20px;">来华留学教育领域专业、系统、全面的在线资源平台</span>
              </div>
            </div>
          </div>
          <div class="correlation_links flex">
            <div class="link_title">相关链接</div>
            <div class="links flex">
              <div class="link" v-for="(item, i) in correlation_links" :key="i">
                <a :href="item.href" target="_blank">{{ item.name }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_right flex">
          <div class="course">
            <div class="title">
              <span>关于我们</span>
              <div class="line"></div>
            </div>
            <ul>
              <li class="noc w150" @click="go_about(item.id)" v-for="(item, i) in company_info_data" :key="i">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="course">
            <div class="title">
              <span>联系我们</span>
              <div class="line"></div>
            </div>
            <ul>
              <li class="noc ">
                <img src="@/assets/dianhua.png" alt="">{{ sys_config_data.concat_tel }}
              </li>
              <li class="noc"> <img src="@/assets/dizhi.png" alt="">{{ sys_config_data.concat_address }}</li>
              <li class="noc"> <img src="@/assets/youxiang.png" alt="">{{ sys_config_data.concat_email }}</li>
            </ul>
          </div>
          <div class="course">
            <ul class="flex">
              <li class="code_li" v-if="sys_config_data.concat_qr_code">
                <img class="qr_code" :src="sys_config_data.concat_qr_code" alt="">
                <div>CSC人才在线</div>
              </li>
              <li class="code_li">
                <img class="qr_code" src="@/assets/qrcode.png" alt="">
                <div>CSC校友</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <div style="margin-right: 10px;">
        <a class="footer_a" target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=沪ICP备18021161号-2">
          <img src="@/assets/gb.png" style="float:left;" />
          <p> 沪ICP备18021161号-2 </p>
        </a>

      </div>
      Copyright © CSC人才在线.
    </div>
  </div>
</template>

<script>
import {
  friend_href,
  sys_config,
  company_info
} from '@/api/login';
import {
  select_options
} from '@/api/series'
export default {
  components: {},
  props: {},
  data() {
    return {
      correlation_links: [],
      select_options_list: {},
      sys_config_data: {},
      company_info_data: []
    }
  },
  computed: {},
  watch: {},
  created() {
    this.get_friend_href();
    // api/sys_config
    this.get_sys_config();
    this.get_company_info();
    // this.get_select_options()
  },
  mounted() { },
  // 组件方法
  methods: {
    go_about(id) {

      let routeUrl = this.$router.resolve({
        path: '/concat',
        query: {
          id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    go_page(id) {

      let routeUrl = this.$router.resolve({
        path: '/course_series',
        query: {
          series: id,
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    get_select_options() {
      select_options().then(res => {
        this.select_options_list = res.data;
      })
    },
    get_company_info() {
      company_info().then(res => {
        this.company_info_data = res.data;
      })
    },
    get_sys_config() {
      sys_config().then(res => {
        this.sys_config_data = res.data;
      })
    },
    get_friend_href() {
      friend_href().then(res => {
        this.correlation_links = res.data;
      })
    },
  }
}
</script>

<style scoped lang="scss">
.footer {
  color: #fff;
  font-size: 16px;

  a {
    color: #fff;
    font-size: 14px;
  }

  .footer_box {
    background: #343538;
    padding: 30px 0;

    .box {
      display: flex;
      width: 1500px;
    }

    .correlation_links {
      margin: 30px 0 0 100px;

      .link_title {
        font-size: 14px;
      }

      .links {
        flex-wrap: wrap;

        .link {
          margin: 0 0 0 20px;
        }
      }
    }

    .column {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;


      .text {
        width: 100%;

        .title {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          line-height: 27px;

          span {
            font-size: 14px;
            font-family: AlibabaPuHuiTi;
            font-weight: 300;
            color: #FFFFFF;
          }
        }

        .line {
          width: 39px;
          height: 5px;
          background: #ffffff;
          border-radius: 3px;
          margin: 12px 0;
        }

        .about_us {
          margin-bottom: 30px;
          font-size: 14px;
        }


      }
    }

    .logo {
      width: 87px;
      height: 87px;
      border-radius: 87px;
      margin-right: 20px;
      object-fit: contain;
      background-color: #fff;
    }

    .code_li {
      margin-right: 30px;

      div {
        margin-top: -10px;
        text-align: center;
        font-size: 16px;
        font-family: AlibabaPuHuiTi;
        font-weight: 300;
        color: #FFFFFF;
      }
    }

    .footer_right {
      border-left: 1px solid #fff;
      min-height: 244px;
      padding-left: 40px;
      justify-content: space-between;
      flex: 1;

      .qr_code {
        width: 90px;
        height: 90px;
        // background: pink;
        // margin-bottom: 20px;
      }

      .course {
        .title {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 20px;

          .line {
            width: 32px;
            height: 5px;
            background: #FFFFFF;
            border-radius: 2px;
          }
        }

        ul {
          min-height: 150px;

          li {
            line-height: 38px;
            cursor: pointer;
          }
        }

        .noc {
          cursor: default;
          display: flex;
          // align-items: center;
          width: 200px;
          line-height: 22px;
          margin-bottom: 20px;
          font-size: 14px;

          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin-right: 10px;
          }
        }

        .w150 {
          width: 150px;
        }
      }
    }
  }

  .footer_bottom {
    height: 50px;
    background: #666666;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #939393;
    line-height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .footer_a {
      display: flex;
      align-items: center;
      text-decoration: none;
      height: 20px;
      line-height: 20px;

      p {
        float: left;
        height: 20px;
        line-height: 20px;
        margin: 0px 0px 0px 5px;
        color: #939393;
      }

      &:hover {
        p {
          color: #fff;
        }
      }
    }
  }
}
</style>
