/****   request.js   ****/
// 导入axios
import axios from 'axios';
import { Message } from 'element-ui';
import router from '../router/index';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
const service = axios.create({
  // 公共接口
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时时间
  timeout: 5000,
});
service.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type': 'application/json', //配置请求头
    };
    const token = localStorage.getItem('token');

    NProgress.start();
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  response => {
    NProgress.done();
    if (response.data.code == 200) {
      return response.data;
    } else if (response.data.code == 401) {
      // Message.error(response.data.message);
      return router.push('/login');
    } else if (response.data.code == 410 || response.data.code == 411) {
      return Promise.resolve(response.data);
    } else {
      Message.error(response.data.message);
      return Promise.resolve(response.data);
    }
  },
  error => {
    //  异常响应
    if (error && error.response) {
      var code = error.response.status;
      if (code == 401) {
        // 登录
        return (error.message = '请先登录');
      } else if (code == 404 || code == 500) {
        return (error.message = '请求失败');
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        Message.error('服务器响应超时');
      }
      return (error.message = '连接服务器失败');
    }
    Message.error(error.message);
    return Promise.resolve(error.response);
  }
);
export default service;
