<!--<style scoped>-->
<!--@media screen and (min-width: 460px) {-->
<!--  .wh_item_date:hover {-->
<!--    background: #71c7a5;-->
<!--    cursor: pointer;-->
<!--  }-->
<!--}-->
<!--* {-->
<!--  margin: 0;-->
<!--  padding: 0;-->
<!--}-->

<!--.wh_container {-->
<!--  max-width: 410px;-->
<!--  margin: auto;-->
<!--}-->

<!--li {-->
<!--  list-style-type: none;-->
<!--}-->
<!--.wh_top_changge {-->
<!--  display: flex;-->
<!--}-->

<!--.wh_top_changge li {-->
<!--  cursor: pointer;-->
<!--  display: flex;-->
<!--  color: #fff;-->
<!--  font-size: 18px;-->
<!--  flex: 1;-->
<!--  justify-content: center;-->
<!--  align-items: center;-->
<!--  height: 47px;-->
<!--}-->

<!--.wh_top_changge .wh_content_li {-->
<!--  cursor: auto;-->
<!--  flex: 2.5;-->
<!--}-->
<!--.wh_content_all {-->
<!--  font-family: -apple-system, BlinkMacSystemFont, "PingFang SC",-->
<!--    "Helvetica Neue", STHeiti, "Microsoft Yahei", Tahoma, Simsun, sans-serif;-->
<!--  background-color: #0fc37c;-->
<!--  width: 100%;-->
<!--  overflow: hidden;-->
<!--  padding-bottom: 8px;-->
<!--}-->

<!--.wh_content {-->
<!--  display: flex;-->
<!--  flex-wrap: wrap;-->
<!--  padding: 0 3% 0 3%;-->
<!--  width: 100%;-->
<!--}-->

<!--.wh_content:first-child .wh_content_item_tag,-->
<!--.wh_content:first-child .wh_content_item {-->
<!--  color: #ddd;-->
<!--  font-size: 16px;-->
<!--}-->

<!--.wh_content_item,-->
<!--wh_content_item_tag {-->
<!--  font-size: 15px;-->
<!--  width: 13.4%;-->
<!--  text-align: center;-->
<!--  color: #fff;-->
<!--  position: relative;-->
<!--  margin-top: 10px;-->
<!--}-->
<!--.wh_content_item {-->
<!--  height: 40px;-->
<!--}-->

<!--.wh_top_tag {-->
<!--  width: 40px;-->
<!--  height: 40px;-->
<!--  line-height: 40px;-->
<!--  margin: auto;-->
<!--  display: flex;-->
<!--  justify-content: center;-->
<!--  align-items: center;-->
<!--}-->

<!--.wh_item_date {-->
<!--  width: 40px;-->
<!--  height: 40px;-->
<!--  line-height: 40px;-->
<!--  margin: auto;-->
<!--  display: flex;-->
<!--  justify-content: center;-->
<!--  align-items: center;-->
<!--}-->

<!--.wh_jiantou1 {-->
<!--  width: 12px;-->
<!--  height: 12px;-->
<!--  border-top: 2px solid #ffffff;-->
<!--  border-left: 2px solid #ffffff;-->
<!--  transform: rotate(-45deg);-->
<!--}-->

<!--.wh_jiantou1:active,-->
<!--.wh_jiantou2:active {-->
<!--  border-color: #ddd;-->
<!--}-->

<!--.wh_jiantou2 {-->
<!--  width: 12px;-->
<!--  height: 12px;-->
<!--  border-top: 2px solid #ffffff;-->
<!--  border-right: 2px solid #ffffff;-->
<!--  transform: rotate(45deg);-->
<!--}-->
<!--.wh_content_item > .wh_isMark {-->
<!--  margin: auto;-->
<!--  border-radius: 100px;-->

<!--  z-index: 2;-->
<!--}-->
<!--.radio {-->
<!--  width: 8px;-->
<!--  height: 8px;-->
<!--  border-radius: 100%;-->
<!--  background-color: blue;-->
<!--  position: absolute;-->
<!--  bottom: 0;-->
<!--  left: 0;-->
<!--  right: 0;-->
<!--  margin: auto;-->
<!--}-->
<!--.wh_content_item .wh_other_dayhide {-->
<!--  color: #bfbfbf;-->
<!--}-->
<!--.wh_content_item .wh_want_dayhide {-->
<!--  color: #bfbfbf;-->
<!--}-->
<!--.wh_content_item .wh_isToday {-->
<!--  background: yellow;-->
<!--  border-radius: 100px;-->
<!--}-->
<!--.wh_content_item .wh_chose_day {-->
<!--  background: green;-->
<!--  border-radius: 100px;-->
<!--}-->
<!--</style>-->

<!--<template>-->
<!--  <section class="wh_container">-->
<!--    <div class="wh_content_all">-->
<!--      <div class="wh_top_changge">-->
<!--        <li @click="PreMonth(myDate, false)">-->
<!--          <div class="wh_jiantou1"></div>-->
<!--        </li>-->
<!--        <li class="wh_content_li">{{ dateTop }}</li>-->
<!--        <li @click="NextMonth(myDate, false)">-->
<!--          <div class="wh_jiantou2"></div>-->
<!--        </li>-->
<!--      </div>-->
<!--      <div class="wh_content">-->
<!--        <div class="wh_content_item" v-for="tag in textTop">-->
<!--          <div class="wh_top_tag">{{ tag }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="wh_content">-->
<!--        <div-->
<!--          class="wh_content_item"-->
<!--          v-for="(item, index) in list"-->
<!--          :key="index"-->
<!--          @click="clickDay(item, index)"-->
<!--        >-->
<!--          <div-->
<!--            class="wh_item_date"-->
<!--            v-bind:class="[-->
<!--              { wh_isMark: item.isMark },-->
<!--              { wh_other_dayhide: item.otherMonth !== 'nowMonth' },-->
<!--              { wh_want_dayhide: item.dayHide },-->
<!--              { wh_isToday: item.isToday },-->
<!--              { wh_chose_day: item.chooseDay },-->
<!--              setClass(item),-->
<!--            ]"-->
<!--          >-->
<!--            {{ item.id }}-->
<!--          </div>-->
<!--          <div v-if="item.isMark" class="radio"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--</template>-->
<!--<script>-->
<!--import timeUtil from "./calendar";-->
<!--import { get_all_activity } from "@/api/login";-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      myDate: [],-->
<!--      list: [],-->
<!--      historyChose: [],-->
<!--      dateTop: "",-->
<!--      markDate: [],-->
<!--    };-->
<!--  },-->
<!--  props: {-->
<!--    markDateMore: {-->
<!--      type: Array,-->
<!--      default: () => [],-->
<!--    },-->
<!--    textTop: {-->
<!--      type: Array,-->
<!--      default: () => ["一", "二", "三", "四", "五", "六", "日"],-->
<!--    },-->
<!--    sundayStart: {-->
<!--      type: Boolean,-->
<!--      default: () => false,-->
<!--    },-->
<!--    agoDayHide: {-->
<!--      type: String,-->
<!--      default: `0`,-->
<!--    },-->
<!--    futureDayHide: {-->
<!--      type: String,-->
<!--      default: `2554387200`,-->
<!--    },-->
<!--  },-->
<!--  async created() {-->
<!--    this.intStart();-->
<!--    this.myDate = new Date();-->
<!--    const res = await get_all_activity();-->
<!--    this.markDate = res.data.map((item) => item.activity_at);-->
<!--  },-->
<!--  methods: {-->
<!--    intStart() {-->
<!--      timeUtil.sundayStart = this.sundayStart;-->
<!--    },-->
<!--    setClass(data) {-->
<!--      let obj = {};-->
<!--      obj[data.markClassName] = data.markClassName;-->
<!--      return obj;-->
<!--    },-->
<!--    clickDay: function (item, index) {-->
<!--      if (item.otherMonth === "nowMonth" && !item.dayHide) {-->
<!--        this.getList(this.myDate, item.date);-->
<!--      }-->
<!--      if (item.otherMonth !== "nowMonth") {-->
<!--        item.otherMonth === "preMonth"-->
<!--          ? this.PreMonth(item.date)-->
<!--          : this.NextMonth(item.date);-->
<!--      }-->
<!--    },-->
<!--    ChoseMonth: function (date, isChosedDay = true) {-->
<!--      date = timeUtil.dateFormat(date);-->
<!--      this.myDate = new Date(date);-->
<!--      this.$emit("changeMonth", timeUtil.dateFormat(this.myDate));-->
<!--      if (isChosedDay) {-->
<!--        this.getList(this.myDate, date, isChosedDay);-->
<!--      } else {-->
<!--        this.getList(this.myDate);-->
<!--      }-->
<!--    },-->
<!--    PreMonth: function (date, isChosedDay = true) {-->
<!--      date = timeUtil.dateFormat(date);-->
<!--      this.myDate = timeUtil.getOtherMonth(this.myDate, "preMonth");-->
<!--      this.$emit("changeMonth", timeUtil.dateFormat(this.myDate));-->
<!--      if (isChosedDay) {-->
<!--        this.getList(this.myDate, date, isChosedDay);-->
<!--      } else {-->
<!--        this.getList(this.myDate);-->
<!--      }-->
<!--    },-->
<!--    NextMonth: function (date, isChosedDay = true) {-->
<!--      date = timeUtil.dateFormat(date);-->
<!--      this.myDate = timeUtil.getOtherMonth(this.myDate, "nextMonth");-->
<!--      this.$emit("changeMonth", timeUtil.dateFormat(this.myDate));-->
<!--      if (isChosedDay) {-->
<!--        this.getList(this.myDate, date, isChosedDay);-->
<!--      } else {-->
<!--        this.getList(this.myDate);-->
<!--      }-->
<!--    },-->
<!--    forMatArgs: function () {-->
<!--      let markDate = this.markDate;-->
<!--      let markDateMore = this.markDateMore;-->
<!--      markDate = markDate.map((k) => {-->
<!--        return timeUtil.dateFormat(k);-->
<!--      });-->
<!--      markDateMore = markDateMore.map((k) => {-->
<!--        k.date = timeUtil.dateFormat(k.date);-->
<!--        return k;-->
<!--      });-->
<!--      return [markDate, markDateMore];-->
<!--    },-->
<!--    getList: function (date, chooseDay, isChosedDay = true) {-->
<!--      const [markDate, markDateMore] = this.forMatArgs();-->
<!--      this.dateTop = `${date.getFullYear()}年${date.getMonth() + 1}月`;-->
<!--      let arr = timeUtil.getMonthList(this.myDate);-->
<!--      for (let i = 0; i < arr.length; i++) {-->
<!--        let markClassName = "";-->
<!--        let k = arr[i];-->
<!--        k.chooseDay = false;-->
<!--        const nowTime = k.date;-->
<!--        const t = new Date(nowTime).getTime() / 1000;-->
<!--        //看每一天的class-->
<!--        for (const c of markDateMore) {-->
<!--          if (c.date === nowTime) {-->
<!--            markClassName = c.className || "";-->
<!--          }-->
<!--        }-->
<!--        //标记选中某些天 设置class-->
<!--        k.markClassName = markClassName;-->
<!--        k.isMark = markDate.indexOf(nowTime) > -1;-->
<!--        //无法选中某天-->
<!--        k.dayHide = t < this.agoDayHide || t > this.futureDayHide;-->
<!--        if (k.isToday) {-->
<!--          this.$emit("isToday", nowTime);-->
<!--        }-->
<!--        let flag = !k.dayHide && k.otherMonth === "nowMonth";-->
<!--        if (chooseDay && chooseDay === nowTime && flag) {-->
<!--          this.$emit("choseDay", nowTime);-->
<!--          this.historyChose.push(nowTime);-->
<!--          k.chooseDay = true;-->
<!--        } else if (-->
<!--          this.historyChose[this.historyChose.length - 1] === nowTime &&-->
<!--          !chooseDay &&-->
<!--          flag-->
<!--        ) {-->
<!--          k.chooseDay = true;-->
<!--        }-->
<!--      }-->
<!--      this.list = arr;-->
<!--    },-->
<!--  },-->
<!--  mounted() {-->
<!--    this.getList(this.myDate);-->
<!--  },-->
<!--  watch: {-->
<!--    markDate: {-->
<!--      handler(val, oldVal) {-->
<!--        this.getList(this.myDate);-->
<!--      },-->
<!--      deep: true,-->
<!--    },-->
<!--    markDateMore: {-->
<!--      handler(val, oldVal) {-->
<!--        this.getList(this.myDate);-->
<!--      },-->
<!--      deep: true,-->
<!--    },-->
<!--    agoDayHide: {-->
<!--      handler(val, oldVal) {-->
<!--        this.getList(this.myDate);-->
<!--      },-->
<!--      deep: true,-->
<!--    },-->
<!--    futureDayHide: {-->
<!--      handler(val, oldVal) {-->
<!--        this.getList(this.myDate);-->
<!--      },-->
<!--      deep: true,-->
<!--    },-->
<!--    sundayStart: {-->
<!--      handler(val, oldVal) {-->
<!--        this.intStart();-->
<!--        this.getList(this.myDate);-->
<!--      },-->
<!--      deep: true,-->
<!--    },-->
<!--  },-->
<!--};-->
<!--</script>-->

<template>
  <main>
    <div class="calendar-box">
      <ul class="calendar-top">
        <li class="Smallhand" @click="switchMonth('l')">
          <div class="wh_jiantou1"></div>
        </li>
        <li class="SmallTit">{{ title }}</li>
        <li class="Smallhand" @click="switchMonth('r')">
          <div class="wh_jiantou2"></div>
        </li>
      </ul>
      <div class="weekBox weekMin">
        <div class="weekItemBox" v-for="(item, index) in weekList" :key="index">
          <span class="weekItem">{{ item }}</span>
        </div>
      </div>
      <div class="weekBox">
        <div class="weekItemBox authBox"
          @click="onClickDays(upMounthDays.year, upMounthDays.month, item, 'l')"
          v-for="(item, index) in upMounthDays.days" :key="uuid() + index">
          <div class="weekItem itemHover" :class="[
              {
                itemTxt: markDate.includes(
                  normalizeDays(upMounthDays.year, upMounthDays.month, item)
                ),
              },
              {
                itemClick:
                  todayClick ===
                  normalizeDays(upMounthDays.year, upMounthDays.month, item),
              },
            ]">
            <span> {{ item }}</span>
            <span>
              {{getDayCn(upMounthDays.year, upMounthDays.month, item).term || getDayCn(upMounthDays.year, upMounthDays.month, item).dayCn}}
            </span>

          </div>

        </div>
        <div class="weekItemBox" v-for="(item, index) in nowMounthDays.days" :key="uuid() + index">
          <div class="weekItem itemHover"
            @click="onClickDays(nowMounthDays.year, nowMounthDays.month, item)" :class="[
              {
                itemTxt: markDate.includes(
                  normalizeDays(nowMounthDays.year, nowMounthDays.month, item)
                ),
              },
              {
                itemNow:
                  normalizeDays(
                    nowMounthDays.year,
                    nowMounthDays.month,
                    item
                  ) === todayNow,
              },
              {
                itemClick:
                  todayClick ===
                  normalizeDays(nowMounthDays.year, nowMounthDays.month, item),
              },
            ]">
            <span> {{ item }}</span>
            <span>
              {{getDayCn(nowMounthDays.year, nowMounthDays.month, item).term || getDayCn(nowMounthDays.year, nowMounthDays.month, item).dayCn}}
            </span>
          </div>

        </div>
        <div class="weekItemBox authBox" @click="
            onClickDays(dowMounthDays.year, dowMounthDays.month, item, 'r')
          " v-for="(item, index) in dowMounthDays.days" :key="uuid() + index">
          <div class="weekItem itemHover" :class="[
              {
                itemTxt: markDate.includes(
                  normalizeDays(dowMounthDays.year, dowMounthDays.month, item)
                ),
              },
              {
                itemClick:
                  todayClick ===
                  normalizeDays(dowMounthDays.year, dowMounthDays.month, item),
              },
            ]">
            <span> {{ item }}</span>
            <span>
              {{getDayCn(dowMounthDays.year, dowMounthDays.month, item).term || getDayCn(dowMounthDays.year, dowMounthDays.month, item).dayCn}}
            </span>
          </div>

        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { get_all_activity } from "@/api/login";
import solarLunar from "solarlunar";
export default {
  data() {
    return {
      timeDate: new Date(),
      yearTitle: "",
      monthTit: "",
      weekList: ["一", "二", "三", "四", "五", "六", "日"],
      upMounthDays: {},
      nowMounthDays: {},
      dowMounthDays: {},
      nowWeek: 0,
      markDate: [],
      todayNow: "",
      todayClick: "",
    };
  },
  computed: {
    title() {
      return `${this.yearTitle}年${this.monthTit}月`;
    },
  },
  methods: {
    async init() {
      const res = await get_all_activity();
      this.markDate = res.data.map((item) => item.activity_at);
      this.yearTitle = this.timeDate.getFullYear(); //当前年
      this.monthTit = Number(this.timeDate.getMonth()) + 1; //当前月
      this.getOtherDays(); // 获取前后两个月的天数
      this.nowWeek = this.getWeekDay(this.yearTitle, this.monthTit - 1, 1); //获取当前月第一天是星期几
      this.getauthMonth(); // 计算前后两个月应该显示出来的天数
      this.todayNow = this.normalizeDays(
        this.yearTitle,
        this.monthTit,
        this.timeDate.getDate()
      ); //今天时间  格式化为 xxxx-xx-xx
    },
    // 时间  格式化为 xxxx-xx-xx日历
    normalizeDays(y, m, day) {
      return `${y}-${(m + "").padStart(2, 0)}-${(day + "").padStart(2, 0)}`;
    },
    /**
     * @description: 获取农历
     * @param {*} y 年
     * @param {*} m 月日
     * @param {*} day 天数
     */
    getDayCn(y, m, day) {
      return solarLunar.solar2lunar(y, m, day);
    },
    // 获取前后两个月的天数
    getauthMonth() {
      this.upMounthDays.days = this.upMounthDays.days.slice(
        this.upMounthDays.days.length -
          (this.nowWeek == 0 ? 6 : this.nowWeek - 1)
      );

      this.dowMounthDays.days = this.dowMounthDays.days.slice(
        0,
        42 - this.upMounthDays.days.length - this.nowMounthDays.days.length
      );
    },
    // 切换月份 l上个月 r下个月
    switchMonth(type) {
      switch (type) {
        case "l":
          if (this.monthTit === 1) {
            this.yearTitle--;
            this.monthTit = 12;
          } else {
            this.monthTit--;
          }
          break;
        case "r":
          if (this.monthTit === 12) {
            this.yearTitle++;
            this.monthTit = 1;
          } else {
            this.monthTit++;
          }
          break;
      }
      this.nowWeek = this.getWeekDay(this.yearTitle, this.monthTit - 1, 1);
      this.getOtherDays();
      this.getauthMonth();
    },
    // 点击
    onClickDays(y, m, item, type) {
      if (type) {
        this.switchMonth(type);
      }
      this.todayClick = this.normalizeDays(y, m, item);
      this.$emit("choseDay", this.todayClick);
    },
    // 获取 现在这个月的天数
    getnowDays() {
      this.nowMounthDays = this.getDaysOfMonth(this.yearTitle, this.monthTit);
    },
    // 获取前 后 两个月的天数
    getOtherDays() {
      if (this.monthTit === 1) {
        this.upMounthDays = this.getDaysOfMonth(this.yearTitle - 1, 12);
        this.dowMounthDays = this.getDaysOfMonth(
          this.yearTitle,
          this.monthTit + 1
        );
        return;
      }
      if (this.monthTit === 12) {
        this.upMounthDays = this.getDaysOfMonth(
          this.yearTitle,
          this.monthTit - 1
        );
        this.dowMounthDays = this.getDaysOfMonth(this.yearTitle + 1, 1);
        return;
      }
      this.upMounthDays = this.getDaysOfMonth(
        this.yearTitle,
        this.monthTit - 1
      );
      this.dowMounthDays = this.getDaysOfMonth(
        this.yearTitle,
        this.monthTit + 1
      );
    },
    // 工具函数  年 月  返回这个月多少天
    getDaysOfMonth(y, m) {
      return {
        days: new Array(new Date(y, m, 0).getDate())
          .fill(1)
          .map((item, index) => index + 1),
        year: y,
        month: m,
      };
    },
    // 工具函数 根据年 月 日 返回星期几
    getWeekDay(y, m, d) {
      return new Date(y, m, d).getDay();
    },
    //   随机生成uuid
    uuid() {
      let s = [];
      let hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";
      return s.join("");
    },
  },
  watch: {
    monthTit: {
      handler() {
        this.getnowDays();
        this.getOtherDays();
        this.getauthMonth();
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.calendar-top {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-bottom: 20px;

  li {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .SmallTit {
    flex: 2.5;
    font-size: 18px;
  }
  .wh_jiantou1 {
    width: 12px;
    height: 12px;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    transform: rotate(-45deg);
  }

  .wh_jiantou2 {
    width: 12px;
    height: 12px;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    transform: rotate(135deg);
  }

  .wh_jiantou1,
  .wh_jiantou2 {
    cursor: pointer;
  }
}

.weekMin {
  margin-bottom: 12px;
}
.authBox {
  color: #bfbfbf;
}
.weekBox {
  padding: 0 3%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .weekItemBox {
    height: 50px;
    width: calc(100% / 7);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 4px 0;
    flex-direction: column;
   

    .weekItem {
      width: 50px;
      height: 50px;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: bold;
      span {
        &:nth-child(2) {
        font-size: 12px;
        font-weight: normal;
      }
    }
    }

    .itemHover {
      &:hover {
        background: #123982;
        color: #fff;
        border-radius: 100%;
      }
    }

    .itemTxt {
      position: relative;

      &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color:#123982;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .itemNow {
      background: blue;
      color: #fff;
      border-radius: 100%;
    }

    .itemClick {
      background: #123982;
      color: #fff;
      border-radius: 100%;
    }
  }
}
</style>
