<template>
  <div class="">
    <div class="list flex">
      <div class="item" v-for="(item, i) in 4" :key="i">
        <div class="img_box">
          <img src="@/assets/img.png" class="img" alt="">
          <div class="tag">直播中</div>
        </div>
        <div class="bottom_box">
          <div class="title line-1">海洋与全球气候变化的关系</div>
          <div class="msg line-1">海洋在全球气候变化过程中的作用及气候变化气候变气候变</div>
          <div class="info flex">
            <div class="left flex">
              <img src="@/assets/tx.png" class="avatar" alt="">
              <span>张三老师</span>
            </div>
            <div class="right flex">
              <span>1234报名</span>
              <div class="btn">立即报名</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {

    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  // 组件方法
  methods: {}
}
</script>

<style scoped lang="scss">
.list {
  justify-content: space-between;
  flex-wrap: wrap;

  .item:hover {
    box-shadow: 0px 5px 10px 3px #dbdbdb;
  }

  .item {
    width: calc(25% - 29px);
    margin-bottom: 30px;
    cursor: pointer;

    .img_box {
      position: relative;

      .tag {
        width: 76px;
        height: 30px;
        background: #e94a19;
        border-radius: 0px 10px 0px 10px;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
      }
    }

    .img {
      width: 100%;
      border-radius: 10px;
      height: 180px;
      object-fit: cover;
    }

    .bottom_box {
      padding: 10px;
      background-color: #fff;
      border-radius: 0 0 10px 10px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      justify-content: space-between;

      .title {
        font-weight: 400;
        color: #383838;
        margin-bottom: 15px;
      }

      .msg {
        color: #909090;
        font-size: 14px;
      }

      .info {
        font-size: 14px;
        color: #383838;
        margin-top: 15px;
        justify-content: space-between;

        .right {
          .btn {
            width: 81px;
            height: 32px;
            background: #103994;
            border-radius: 16px;
            text-align: center;
            line-height: 32px;
            color: #fff;
            font-size: 14px;
            margin-left: 20px;
          }
        }
      }

      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        margin-right: 10px;
      }
    }
  }
}
</style>
