//
import http from "../utils/http";
// 全部类型
export function categories(params) {
  return http.get(`/api/categories`, params);
}
// 资源下载列表
export function get_down_list(params) {
  return http.get(`/api/get_down_list`, params);
}
// 资源下载选项
export function get_means_categories(params) {
  return http.get(`/api/get_means_categories`, params);
}

/**
 * @description: 下载资源文件
 * @param {*} params
 */
export function down_file(params) {
  return http.get(`/api/get_down_path`, params);
}

// 直播播放
//let course_details = (params) => vm.$u.get(`/api/show_recommended_course/${params}`);
export function live_broadcast_course_info(params) {
  return http.get(`/api/live_broadcast_course_info/${params}`);
}
// 全部方向
export function stages(params) {
  return http.get(`/api/stages`, params);
}
// 系列
export function series(params) {
  return http.get(`/api/series`, params);
}
// 列表数据
export function goods(params) {
  return http.get(`/api/goods`, params);
}
// 答题交卷
export function submit_answer(params) {
  return http.get(`/api/exercise/submit_answer`, params);
}
// 答题记录
export function records(params) {
  return http.post(`/api/exercise/records`, params);
}
// 获取答题数据
export function get_questions(params) {
  return http.post(`/api/exercise/questions`, params);
}
// 直播
export function live_course(params) {
  return http.get(`/api/index_live_broadcast_course`, params);
}
// 报名直播
export function live_broadcast_course_store(params) {
  return http.post(`/api/live_broadcast_course_store`, params);
}
// 新增加-------- 活动直播 不需要付费报名
export function sub_activity_apply(params, query) {
  return http.get(`/api/user/sub_activity_apply/${params}/${query}`);
}
