import http from '../utils/http'
// 发送验证码
export function send_code(params) {
	return http.post(`/api/oauth/send_code`, params)
}
// 注册
export function register(params) {
	return http.post(`/api/mobile_register`, params)
}
// 上传图片
export function upload_person_photos(params) {
	return http.post(`/api/upload_person_photos`, params)
}
// 提交用户信息
export function user_update(params) {
	return http.post(`/api/user_update`, params)
}
// 密码登录
export function user_login_psd(params) {
	return http.post(`/api/oauth/token`, params)
}
// 验证吗登录
export function user_login_mobile(params) {
	return http.post(`/api/oauth/mobile_login`, params)
}
// 忘记密码
export function mobile_reset_password(params) {
	return http.post(`/api/oauth/mobile_reset_password`, params)
}
// 我的信息
export function my_user(params) {
	return http.get(`/api/user`, params)
}
// 首页轮播
export function index_banner(params) {
	return http.get(`/api/index_banner`, params)
}
// 首页搜索
export function index_search(params) {
	return http.post(`/api/search`, params)
}
// 首页推荐课程
export function index_goods(params) {
	return http.get(`/api/index_goods`, params)
}
// 首页推荐课程分类
export function index_recommend_categories(params) {
	return http.get(`/api/index_recommend_categories`, params)
}
// 首页推荐全部活动
export function get_all_activity(params) {
	return http.get(`/api/get_all_activity`, params)
}
// 首页新闻列表
export function index_news(params) {
	return http.get(`/api/index_news`, params)
}
// 新闻详情
export function news_detail(params) {
	return http.get(`/api/news/` + params)
}
// 新闻详情推荐
export function hot_news(params) {
	return http.get(`/api/hot_news`, params)
}
// 产品介绍页面
export function goods_detail(params) {
	return http.get(`/api/goods/` + params)
}
// 产品课程大纲
export function get_goods_chapter(params) {
	return http.get(`/api/get_goods_chapter/` + params)
}
// 课程老师
export function get_goods_teachers(params) {
	return http.get(`/api/get_goods_teachers/` + params)
}
// 本课程的所有回答
export function get_goods_answer_for_goods(params) {
	return http.get(`/api/get_goods_answer_for_goods/` + params)
}

//提问
export function add_goods_answer_for_goods(query, params) {
	return http.post(`/api/add_goods_answer_for_goods/` + query, params)
}
// 获取活动列表
export function get_activity(params) {
	return http.get(`/api/activity`, params)
}
// 获取活动详情
export function activity_detail(params) {
	return http.get(`/api/activity/` + params)
}
// 创建活动订单
export function activity_order(params) {
	return http.post(`/api/activity_order`, params)
}
// 订单支付
export function activity_order_wexin_payment(params) {
	return http.post(`/api/activity_order_wexin_payment/` + params)
}
// 获取课程资料
export function get_goods_files(params) {
	return http.get(`/api/get_goods_files/` + params)
}
// 获取当天日期是否有活动
export function get_day_activity(params) {
	return http.get(`/api/get_day_activity`, params)
}
// 底部友情链接
export function friend_href(params) {
	return http.get(`/api/friend_href`, params)
}

export function sys_config(params) {
	return http.get(`/api/sys_config`, params)
}

export function company_info(params) {
	return http.get(`/api/company_info`, params)
}

export function company_info_detail(params) {
	return http.get(`/api/company_info_detail/${params}`)
}

// 用户附件 /api//
export function activity_demands(params) {
	return http.get(`/api/user/activity_demands`, params)
}